import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { getSelectedProductIdFromActionInput } from '../../utilities/utils';

export class ProductQuantityStateInput implements IActionInput {
    public productId: number;

    constructor(productId: number) {
        this.productId = productId;
    }

    public getCacheKey = () => `ProductQuantityState-${this.productId}`;
    public getCacheObjectType = () => 'number';
    public dataCacheType = (): CacheType => 'instance';
}

export async function ProductQuantityStateAction(input: ProductQuantityStateInput, ctx: IActionContext): Promise<number> {
    return <number>{};
}

const createInput = (inputData: ICreateActionContext<IGeneric<IAny>>): IActionInput => {
    const selectedId = getSelectedProductIdFromActionInput(inputData);
    if (selectedId) {
        return new ProductQuantityStateInput(+selectedId);
    }
    throw new Error('Unable to find warranty disabled state, no productId found on module config or query');
};

export default createObservableDataAction<number>({
    action: <IAction<number>>ProductQuantityStateAction,
    input: createInput
});
