import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICreateActionContext
} from '@msdyn365-commerce/core';
import { IProductWarranties } from './DataServiceEntities.g';
import { getProductWarrantiesAction, ProductWarrantiesInput } from './get-product-warranties.action';
import { getActiveCartProductsAction, ActiveCartProductsInput } from '@msdyn365-commerce-modules/retail-actions';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/*
 * Input class for the getProductWarrantiesByCartInput data action
 */
export class GetProductWarrantiesByCartInput implements IActionInput {
    public getCacheKey = () => `mmx-productwarrantiesinactivecart`;
    public getCacheObjectType = () => 'IProductWarranties';
    public dataCacheType = (): CacheType => 'none';
}

/**
 * Create input method for the getProductWarrantiesByCart data action.
 */
export const createProductWarrantiesByCartInput = (args: ICreateActionContext): IActionInput => {
    return new GetProductWarrantiesByCartInput();
};

/**
 * Action method for the getProductWarrntiesByCart data action.
 * @param input
 * @param ctx
 */
export async function getProductWarrntiesByCartAction(
    input: GetProductWarrantiesByCartInput,
    ctx: IActionContext
): Promise<IProductWarranties[]> {
    var productWarrantiesInput: ProductWarrantiesInput[] = [];

    return getActiveCartProductsAction(new ActiveCartProductsInput(), ctx).then((response: SimpleProduct[]) => {
        response.map(cartProduct => {
            productWarrantiesInput.push(new ProductWarrantiesInput(cartProduct.RecordId));
        });

        return getProductWarrantiesAction(productWarrantiesInput, ctx).then((response: IProductWarranties[]) => {
            return response;
        });
    });
}

/**
 * The GetProductWarrantiesByCart data action
 * Gets all products in the active cart and finds the applicable
 * warranty for each product via the getProductWarranties RetailServer API
 */
export default createObservableDataAction({
    id: 'get-product-warranties-by-cart',
    action: <IAction<IProductWarranties[]>>getProductWarrntiesByCartAction,
    input: createProductWarrantiesByCartInput,
    isBatched: false
});
