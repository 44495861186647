const binding = { modules: {}, dataActions: {} };

    const registerActionId = (actionPath) => {
        if (binding.dataActions[actionPath] &&
            binding.dataActions[actionPath].default &&
            binding.dataActions[actionPath].default.prototype &&
            binding.dataActions[actionPath].default.prototype.id) {
            binding.dataActions[binding.dataActions[actionPath].default.prototype.id] = binding.dataActions[actionPath];
        } else {
            Object.keys(binding.dataActions[actionPath] || {}).forEach(exportName => {
                if (binding.dataActions[actionPath][exportName] &&
                    binding.dataActions[actionPath][exportName].prototype &&
                    binding.dataActions[actionPath][exportName].prototype.Action) {
                    binding.dataActions[binding.dataActions[actionPath][exportName].prototype.id] = binding.dataActions[actionPath][exportName];
                }
            })
        }
    };

    const registerSanitizedActionPath = (sanitizedActionPath, dataAction) => {
        if (process.env.NODE_ENV === 'development') {
            if (!dataAction.default) {
                throw new Error('Data action path does not have a default export');
            }
            if (!(dataAction.default.prototype.id && binding.dataActions[dataAction.default.prototype.id]) || !binding.dataActions[sanitizedActionPath]) {
                binding.dataActions[sanitizedActionPath] = dataAction;
            }
        } else {
            binding.dataActions[sanitizedActionPath] = dataAction;
            if (!binding.dataActions[sanitizedActionPath].default) {
                throw new Error('Data action path ' + sanitizedActionPath + ' does not have a default export');
            }
            binding.dataActions[sanitizedActionPath].default.prototype.RegistrationId = sanitizedActionPath;
            if (binding.dataActions[sanitizedActionPath].default.prototype.id) {
                binding.dataActions[binding.dataActions[sanitizedActionPath].default.prototype.id] = sanitizedActionPath;
            }
        }
    };
    

    (binding.modules['memoryexpress'] = {
        c: () => require('partner/themes/memoryexpress/memoryexpress.tsx'),
        $type: 'themeModule',
        da: [{name:'deliveryOptionContext',  path:'actions/get-delivery-option-context.action', moduleName: 'cart',runOn: 0},{name:'productQuantityStateInput',  path:'actions/state-data-actions/product-quantity-state.action', moduleName: 'mmx-buybox',runOn: 0},{name:'productWarrantiesInActiveCart',  path:'actions/get-product-warranties-in-active-cart.action', moduleName: 'cart-icon',runOn: 0},{name:'productWarrantiesInActiveCart',  path:'actions/get-product-warranties-in-active-cart.action', moduleName: 'cart',runOn: 0},{name:'productWarrantyState',  path:'actions/state-data-actions/product-warranty-state.action', moduleName: 'mmx-buybox',runOn: 0}],
        definitionExtensions: ['active-image','b2b-requests-status','business-account-address','business-organization-list','buybox','cart-icon','cart','content-block','footer-item','header','image-list','mmx-buybox','mmx-quickview','mmx-search-result-container','mmx-store-selector','navigation-menu','order-template-list','promo-banner','quickview','ratings-histogram','reviews-list','search-result-container','search','sign-in','store-selector','text-block','write-review'],
        iNM: false,
        ns: '__local__',
        n: 'memoryexpress',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'memoryexpress.theme.settings.json',
        md: 'src/themes/memoryexpress'
    });
        

        {
            const sanitizedActionPath = 'actions/get-delivery-option-context.action';
            let dataAction = require('partner/actions/get-delivery-option-context.action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/get-product-warranties-in-active-cart.action';
            let dataAction = require('partner/actions/get-product-warranties-in-active-cart.action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/get-product-warranties-in-active-cart.action';
            let dataAction = require('partner/actions/get-product-warranties-in-active-cart.action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/state-data-actions/product-quantity-state.action';
            let dataAction = require('partner/actions/state-data-actions/product-quantity-state.action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        {
            const sanitizedActionPath = 'actions/state-data-actions/product-warranty-state.action';
            let dataAction = require('partner/actions/state-data-actions/product-warranty-state.action');
            registerSanitizedActionPath(sanitizedActionPath, dataAction);
        }
            

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };