import {
    CacheType,
    createObservableDataAction,
    IAction,
    ICreateActionContext,
    IActionContext,
    IActionInput,
    IGeneric,
    IAny
} from '@msdyn365-commerce/core';
import { getProductWarrantiesAsync } from './DataActionExtension.g';
import { IProductWarranties, IProductDefaultVariant } from './DataServiceEntities.g';
import { getProductDefaultVariantAction, ProductDefaultVariantInput } from './get-product-default-variant.action';
import { getSelectedProductIdFromActionInput } from '../utilities/utils';

/*
 * Input class for the getProductWarranties data action
 */
export class ProductWarrantiesInput implements IActionInput {
    public productId: number;
    constructor(productId: number) {
        this.productId = productId;
    }
    public getCacheKey = () => `mmx-productwarranties-${this.productId}`;
    public getCacheObjectType = () => 'IProductWarranties';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * Create input method for the getProductWarranties data action.
 * @param inputData The input data passed to the createInput method.
 */
export const createProductWarrantiesInput = (inputData: ICreateActionContext<IGeneric<IAny>>): ProductWarrantiesInput[] => {
    const productIds: string[] = [];
    const selectedId = getSelectedProductIdFromActionInput(inputData);
    if (selectedId) {
        productIds.push(selectedId);
        if (Array.isArray(productIds) && productIds.length > 0) {
            return productIds.map((productId: string) => {
                return new ProductWarrantiesInput(+productId);
            });
        }
    }
    throw new Error('Unable to find product warranties, no productId found on module config or query');
};

/**
 * Action method for the getProductWarranties data action.
 * @param inputs
 * @param ctx
 */
export async function getProductWarrantiesAction(input: ProductWarrantiesInput[], ctx: IActionContext): Promise<IProductWarranties[]> {
    if (!Array.isArray(input) || input.length === 0) {
        ctx.telemetry.trace('[getProductWarranties] Invalid or empty input.');
        return <IProductWarranties[]>{};
    }

    const productDefaultVariantInputs: ProductDefaultVariantInput[] = input.map(
        (input: ProductWarrantiesInput): ProductDefaultVariantInput => {
            return new ProductDefaultVariantInput(input.productId);
        }
    );

    return getProductDefaultVariantAction(productDefaultVariantInputs, ctx).then((response: IProductDefaultVariant[]) => {
        const defaultProductMasterIds: number[] = [];
        const defaultVariantIds: number[] = [];

        response.map(response => {
            defaultProductMasterIds.push(response.MasterId);
            defaultVariantIds.push(response.DefaultProductId);
        });

        // Product Ids not accounted for by the getProductDefaultVariantAction response
        const missingInputIds = input
            .filter(input => !defaultProductMasterIds.includes(input.productId))
            .map(input => {
                return input.productId;
            });

        const allProductIds = [...new Set([...defaultVariantIds, ...missingInputIds])];

        return getProductWarrantiesAsync({ callerContext: ctx }, allProductIds).then((response: IProductWarranties[]) => {
            return response;
        });
    });
}

/**
 * The GetProductWarranties data action
 * Finds variants from product masters or uses
 * Then finds the applicable warranties via the getProductWarranties RetailServer API.
 */
export default createObservableDataAction({
    id: 'get-product-warranties',
    action: <IAction<IProductWarranties[] | null>>getProductWarrantiesAction,
    input: createProductWarrantiesInput,
    isBatched: true
});
