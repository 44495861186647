import {
    CacheType,
    createObservableDataAction,
    IAction,
    ICreateActionContext,
    IActionContext,
    IActionInput,
    IGeneric,
    IAny
} from '@msdyn365-commerce/core';
import { getProductDefaultVariantsAsync } from './DataActionExtension.g';
import { IProductDefaultVariant } from './DataServiceEntities.g';
import { getSelectedProductIdFromActionInput } from '../utilities/utils';

/*
 * Input class for the getProductDefaultVariant data action
 */
export class ProductDefaultVariantInput implements IActionInput {
    public productId: number;
    constructor(productId: number) {
        this.productId = productId;
    }
    public getCacheKey = () => `mmx-productdefaultvariant-${this.productId.toString()}`;
    public getCacheObjectType = () => 'IProductDefaultVariant';
    public dataCacheType = (): CacheType => 'application';
}

/**
 * Create input method for the getProductDefaultVariant data action.
 * @param inputData The input data passed to the createInput method.
 */
export const createProductDefaultVariantInput = (inputData: ICreateActionContext<IGeneric<IAny>>): ProductDefaultVariantInput[] => {
    const productIds: string[] = [];
    const selectedId = getSelectedProductIdFromActionInput(inputData);
    if (selectedId) {
        productIds.push(selectedId);
        if (Array.isArray(productIds) && productIds.length > 0) {
            return productIds.map((productId: string) => {
                return new ProductDefaultVariantInput(+productId);
            });
        }
    }
    throw new Error('Unable to find product variant, no productId found on module config or query');
};

/**
 * Action method for the getProductDefaultVariant data action.
 * @param input
 * @param ctx
 */
export async function getProductDefaultVariantAction(
    input: ProductDefaultVariantInput[],
    ctx: IActionContext
): Promise<IProductDefaultVariant[]> {
    const productIds = input.map((input: ProductDefaultVariantInput): number => {
        return input.productId;
    });
    return getProductDefaultVariantsAsync({ callerContext: ctx }, productIds).then((response: IProductDefaultVariant[]) => {
        return response;
    });
}

/**
 * The GetProductDefaultVariant data action
 * Finds product the default variants for given product masters via the RetailServer API.
 */
export default createObservableDataAction({
    id: 'get-product-default-variant',
    action: <IAction<IProductDefaultVariant[] | null>>getProductDefaultVariantAction,
    input: createProductDefaultVariantInput,
    isBatched: true
});
